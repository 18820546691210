<template>
	<div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default{
	
	methods:{
		...mapActions({
			logoutUser: "user/logout"
		})
	},
	mounted(){
		this.logoutUser()
	}
}
</script>