<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on, attrs }">
      <div style="width: 50px">
        <v-btn id="lang" fab v-bind="attrs" v-on="on">
          {{ language }}
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="n in supportedLanguages"
        :key="n.locale"
        @click="changeLanguage(n.locale)"
      >
        <v-list-item-title>{{ n.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import i18n from "@/plugins/i18n"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "LanguageMenu",
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      supportedLanguages: "language/supportedLanguages",
      language: "language/getLanguage",
    }),
    items() {
      return [{ heading: this.$t("translations") }, ...this.locales]
    },
  },
  methods: {
    changeLanguage(locale) {
      this.$store.dispatch("language/setLanguage", locale).then(() => {
        i18n.locale = this.$store.state.language.locale
      })
    },
  },
}
</script>
