<template>
	<v-container fluid class="pa-10" >
		<v-row>
			<v-col>
				<v-text-field outlined v-model="doc_key" label="Doc key from different customer" ref="document_key" />
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-btn @click="switchcust()" :disabled="doc_key == null" color="primary">
					Switch Customer
				</v-btn>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
import { mapActions } from 'vuex';
import router  from "../../../router"

export default {

	data(){
		return {
			doc_key: null,
		}
	},
	methods: {
		...mapActions({
			switchCustomer: "user/switchCustomer",
		}),
		switchcust(){
			this.switchCustomer({
				payload: {
					doc_key: this.doc_key
				}
			}).then((response) =>{
				return router.push({ path: '/document/' + this.doc_key})
			}).catch((error) =>{
				this.$snackbar.showMessage({ content: "Invalid entry", color: 'error' })
			})
		},
	},
	mounted(){
		this.$refs.document_key.focus();
	},


}

</script>