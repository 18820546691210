<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card class="pa-5">
      <v-card-title class="headline">
        Reset password
      </v-card-title>

      <v-card-text>Enter your email to reset your password</v-card-text>

      <v-row class="pa-5">
        <v-col>
          <v-text-field
            outlined
            v-model.trim="email"
            name="email"
            label="Email"
          />
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn primary @click="dialog = false">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- 
  <div class="modal">
    <div class="modal-content">
      <div @click="$emit('close')" class="close">close</div>
      <h3>Reset Password</h3>
      <div v-if="!showSuccess">
        <p>Enter your email to reset your password</p>
        <form @submit.prevent>
          <input v-model.trim="email" type="email" placeholder="you@email.com" />
        </form>
        <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
        <button @click="resetPassword()" class="button">Reset</button>
      </div>
      <p v-else>Success! Check your email for a reset link.</p>
    </div>
  </div> -->
</template>

<script>
import { auth } from "@/firebase"

export default {
  data() {
    return {
      email: "",
      dialog: true,
      showSuccess: false,
      errorMsg: "",
    };
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ""

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    },
  },
}
</script>
